import React from "react";
import { Tag } from "antd";
import { RFQStatus } from "../../util/Constant";

const statusDetails = {
  [RFQStatus.DRAFT]: {
    label: "Draft",
    color: "black",
  },
  [RFQStatus.PENDING_APPROVAL]: {
    label: "Pending Approval",
    color: "#A3A3AD",
  },
  [RFQStatus.PUBLISHED]: {
    label: "Published",
    color: "#40507E",
  },
  [RFQStatus.QUOTATION_RECEIVED]: {
    label: "Quotation Received",
    color: "#F5993F",
  },
  [RFQStatus.QUOTATION_SENT]: {
    label: "New Quotation",
    color: "#F5993F",
  },
  [RFQStatus.ORDER_PLACED]: {
    label: "Order Placed",
    color: "#40507E",
  },
  [RFQStatus.ORDER_CONFIRMED]: {
    label: "Order Confirmed",
    color: "#2FB07E",
  },
  [RFQStatus.REJECTED]: {
    label: "Rejected",
    color: "#FF0000",
  },
  [RFQStatus.AWAITING_FOR_QUOTATION]: {
    label: "Awaiting For Quotation",
    color: "#9d9d9d",
  },
};

const RFQStatusTags = ({ status }) => {
  return (
    <div>
      <Tag color={statusDetails[status]?.color}>
        {statusDetails[status]?.label}
      </Tag>
    </div>
  );
};

export default RFQStatusTags;
