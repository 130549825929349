import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import { Dropdown } from "antd";
import { EditOutlined, LeftOutlined, MoreOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { showAlert } from "../../util/helper";
import { isValidString } from "../../util/ApiHelper";
import { MY_EXIM_API_RFQ } from "../../appRedux/services/requestQuotationsNetwork";
import { useDispatch, useSelector } from "react-redux";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { RFQStatus } from "../../util/Constant";
import RFQStatusTags from "./RFQStatus";
import { formatDate } from "util/helper";

let navigateToDetails;

const QuotationsList = () => {
  const [quotationList, SetQuotationList] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  navigateToDetails = (id) => {
    history.push(`/rfq/quotations-details/${id}`);
  };

  const countries = useSelector(({ common }) => common.countries);

  const getSymbolForCurrency = (currencyCode) => {
    const country = countries.find((c) => c.currencyCode === currencyCode);
    return country ? country.curencySymbol : "";
  };

  useEffect(() => {
    callQuotationList();
  }, []);

  const callQuotationList = (page = 1, limit = 10) => {
    dispatch(fetchStart());
    MY_EXIM_API_RFQ.getQuotationList({ page, limit, enquiryId: id })
      .then((response) => {
        let dataItem = response?.data?.data;
        if (dataItem) {
          SetQuotationList(dataItem);
        }
        dispatch(fetchSuccess());
      })
      .catch((e) => {
        if (isValidString(e?.message)) {
          showAlert(e?.message);
        }
        dispatch(fetchSuccess());
      });
  };

  const columns = [
    {
      title: "Received Date",
      key: "createdAt",
      render: (record) => <div>{formatDate(record?.createdAt)}</div>,
    },
    {
      title: "Product Name",
      key: "productName",
      sorter: true,
      render: (record) => <div>{record?.enquiries?.productName}</div>,
    },
    {
      title: "Company Name",
      key: "user",
      render: (record) => (
        <div className="highlighted-column">{record?.sender?.companyName}</div>
      ),
    },
    {
      title: "Price / Unit",
      key: "price",
      sorter: true,
      render: (record) => (
        <div>
          {isValidString(record?.currencyCode) &&
          isValidString(record?.price) &&
          isValidString(record.units)
            ? `${record?.currencyCode} ${record?.price} / ${record.units}`
            : "-"}
        </div>
      ),
    },
    {
      title: "Status",
      key: "country",
      sorter: true,
      render: (record) => (
        <div>
          <RFQStatusTags status={record?.status} />
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <Dropdown
          menu={{
            items: [
              {
                label: "View Details",
                key: "1",
                icon: <EditOutlined />,
                onClick: () => {
                  navigateToDetails(record?.id);
                },
              },
            ],
          }}
        >
          <div
            className="myexim-action-icon"
            onClick={(e) => e.preventDefault()}
          >
            <MoreOutlined />
          </div>
        </Dropdown>
      ),
    },
  ];

  return (
    <Card
      title={
        <div className="myexim-table-title">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          Quotations
        </div>
      }
    >
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        className="gx-table-responsive"
        columns={columns}
        dataSource={quotationList?.rows}
        pagination={{
          position: ["bottomRight"],
          pageSize: 10,
          showSizeChanger: true,
          onChange: callQuotationList,
        }}
        onRow={(record) => {
          return {
            onClick: (e) => {
              // Add specific conditions here for menu items
              if (
                !e.target.classList.contains("ant-dropdown-menu-title-content")
              ) {
                navigateToDetails(record?.id);
              }
            },
          };
        }}
      />
    </Card>
  );
};

export default QuotationsList;
