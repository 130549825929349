import React from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";

import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import OTPVerify from "../OTPVerify";
import CheckEmail from "../CheckEmail";
import PasswordSuccess from "../PasswordSuccess";
import ExporterForm from "../ExporterForm";
import PrivacyPolicy from "../PrivacyPolicyScreen";
import TermsOfService from "../TermsOfService";
import SuccessPage from "../SuccessPage";

export const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const App = () => {
  const authUser = useSelector(({ auth }) => auth.userData);
  const location = useLocation();
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/registrationsuccess" component={SuccessPage} />
      <Route exact path="/exporter" component={ExporterForm} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
      <Route exact path="/resetpassword" component={ResetPassword} />
      <Route exact path="/otpverify" component={OTPVerify} />
      <Route exact path="/checkemail" component={CheckEmail} />
      <Route exact path="/passwordsuccess" component={PasswordSuccess} />
      <Route exact path="/terms-of-service" component={TermsOfService} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <RestrictedRoute
        path={`${match.url}`}
        authUser={authUser}
        location={location}
        component={MainApp}
      />
    </Switch>
  );
};

export default React.memo(App);
