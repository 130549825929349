import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import {
  HomeOutlined,
  LogoutOutlined,
  CommentOutlined,
  ProfileOutlined,
  FileTextOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { isAdminApp, isUserApp } from "../../util/helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  isExporter,
  isBuyer,
  isCHA,
  isShipping,
  isProductApplicable,
} from "./../../util/helper";
import { AUTH_TYPES } from "../../appRedux/types/authTypes";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const dispatch = useDispatch();
  const { themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const userData = useSelector(({ auth }) => auth?.userData);

  let selectedKeys = pathname.split("/")[1];
  if (selectedKeys === "") {
    selectedKeys = "home";
  }
  const history = useHistory();
  const onLogoutClick = () => {
    dispatch({ type: AUTH_TYPES.LOGOUT_ACTION });
    localStorage.removeItem("token");
    history.replace("/signin");
  };

  const renderUserSideMenu = () => {
    return (
      <>
        <Menu.Item key="home">
          <Link to="/">
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Menu.Item>
        {!isBuyer(userData) && (
          <Menu.Item key="enquiries">
            <Link to="/enquiries">
              <CommentOutlined />
              <span>
                {isCHA(userData) || isShipping(userData) || isExporter(userData)
                  ? "Enquiries"
                  : "Enquiries"}
              </span>
            </Link>
          </Menu.Item>
        )}
        {isProductApplicable(userData) && (
          <Menu.Item key="products">
            <Link to="/my-products">
              <ProfileOutlined />
              <span>My Products</span>
            </Link>
          </Menu.Item>
        )}
        {(isBuyer(userData) || isExporter(userData)) && (
          <Menu.Item key="rfq">
            <Link to="/rfq">
              <FileTextOutlined />
              <span>My RFQ</span>
            </Link>
          </Menu.Item>
        )}

        {/* {!isBuyer(userData) && !isExporter(userData) && (
          <Menu.Item key="promotions">
            <Link to="/promotions">
              <ProfileOutlined />
              <span>My Promotions</span>
            </Link>
          </Menu.Item>
        )} */}
        <Menu.Item key="logout">
          <Link to="/signin" onClick={onLogoutClick}>
            <LogoutOutlined />
            <span>Logout</span>
          </Link>
        </Menu.Item>
      </>
    );
  };

  const renderAdminSideMenu = () => {
    return (
      <>
        <Menu.Item key="enquiries">
          <Link to="/enquiries">
            <CommentOutlined />
            <span>Enquiries</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="products">
          <Link to="/products">
            <ProfileOutlined />
            <span>Products</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="users">
          <Link to="/users">
            <UserOutlined />
            <span>Users</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="registrations">
          <Link to="/registrations">
            <HomeOutlined />
            <span>Exporter Registartions</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="website-queries">
          <Link to="/website-queries">
            <HomeOutlined />
            <span>Website Queries</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="promotions">
          <Link to="/promotions">
            <ProfileOutlined />
            <span>Promotions</span>
          </Link>
        </Menu.Item>
      </>
    );
  };

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        {/* <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile/>
          <AppsNavigation/>
        </div> */}
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[selectedKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {isUserApp() ? renderUserSideMenu() : null}
            {isAdminApp() ? renderAdminSideMenu() : null}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
