import axios from "axios";
import { call } from "redux-saga/effects";
import { isValidElement, NETWORK_METHOD } from "./ApiHelper";

export const BASE_API_CONFIG = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  requestTimeOut: 40000,
  headers: { "Content-Type": "application/json" },
};

export const axiosInstance = axios.create({
  baseURL: BASE_API_CONFIG.baseURL,
  timeout: BASE_API_CONFIG.requestTimeOut,
  headers: BASE_API_CONFIG.headers,
});

export const STATUS_CODE = {
  OutcomeSuccess: 200,
  BadRequest: 400,
};

export const apiCall = (apiCallFuc, ...args) => {
  return call(function* () {
    let request = { ...apiCallFuc(...args) };
    return yield call(callNetwork, request);
  });
};

export function callNetwork(action) {
  if (isValidElement(action) && isValidElement(action.method)) {
    // eslint-disable-next-line default-case
    switch (action.method) {
      case NETWORK_METHOD.GET: {
        return axiosInstance.get(
          action.url,
          isValidElement(action.config) && action.config
        );
      }
      case NETWORK_METHOD.POST: {
        return axiosInstance.post(
          action.url,
          isValidElement(action.data) && action.data,
          isValidElement(action.config) && action.config
        );
      }
      case NETWORK_METHOD.PUT: {
        return axiosInstance.put(
          action.url,
          isValidElement(action.data) && action.data,
          isValidElement(action.config) && action.config
        );
      }
      case NETWORK_METHOD.DELETE: {
        return axiosInstance.delete(
          action.url,
          isValidElement(action.config) && action.config
        );
      }
      case NETWORK_METHOD.HEAD: {
        return axiosInstance.head(
          action.url,
          isValidElement(action.config) && action.config
        );
      }
      case NETWORK_METHOD.PATCH: {
        return axiosInstance.patch(
          action.url,
          isValidElement(action.data) && action.data,
          isValidElement(action.config) && action.config
        );
      }
    }
  }
}

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
