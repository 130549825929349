
import React  from "react";
import {Button,  Result} from "antd";
import { useHistory } from 'react-router-dom';

const PasswordSuccess = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/signin')
  }

  return (

    <div className="gx-login-container">
      <div className="gx-login-content">
      <Result status="success" title="Password Created Successfully"
            extra={
              <Button type="primary" size="large"  block onClick={handleClick}>
              Login
            </Button>
          }
        />

      </div>
    </div>

  );
}

export default PasswordSuccess;
