/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, List } from "antd";
import { isValidElement, isValidString } from "../../util/ApiHelper";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LeftOutlined } from "@ant-design/icons";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import EnquiryStatusTags from "../../admin/enquiries/EnquiriesStatus";
import { getNewLine } from "./../../util/helper";
import { useSelector } from "react-redux";

const MyProductView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isFromUserProfile = location.state?.isFromUserProfile;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const authUser = useSelector(({ auth }) => auth.userData);

  const [productData, setProductData] = useState(null);

  const getProductById = async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const productById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/products/${id}`
        );
        setProductData(productById?.data?.data);
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };

  useEffect(() => {
    getProductById(id);
  }, [id]);

  const getProductSpec = (inputValue) => {
    const values = getNewLine(inputValue);
    return values;
  };

  const data = [
    {
      label: "Name",
      value: productData?.name,
    },
    {
      label: "Category",
      value: productData?.categories?.name,
    },
    {
      label: "HS Code",
      value: `${productData?.hscode?.name} - ${productData?.hscode?.description}`,
    },
    {
      label: "Description",
      value: getNewLine(productData?.description),
    },
    {
      label: "Specification",
      value: getProductSpec(productData?.specification),
    },
    {
      label: "Remarks",
      value: productData?.remarks,
    },
    ...(!isFromUserProfile
      ? [
          {
            label: "Status",
            value: <EnquiryStatusTags status={productData?.status} />,
          },
        ]
      : []),
  ];
  const navigateProductEdit = () => {
    history.push(`edit?id=${id}`);
  };

  return (
    isValidElement(productData) && (
      <Card className="myexim-detail-card" style={{ width: "75%" }}>
        <div className="myexim-table-title">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          Product Details
        </div>
        <div className="logo-with-list gx-mt-4">
          <div style={{ paddingRight: 16 }}>
            {isValidString(productData?.primaryImage) && (
              <div className={"logo-container"}>
                <div className={"logo-image"}>
                  <img
                    alt="..."
                    src={productData?.primaryImage}
                    width={120}
                    height={120}
                  />
                </div>
              </div>
            )}
          </div>
          <div style={{ flex: 1 }}>
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) =>
                item?.value && (
                  <List.Item className="myexim-details-list">
                    <List.Item.Meta
                      title={<div>{item.label}</div>}
                      description={isValidString(item.value) ? item.value : "-"}
                    />
                  </List.Item>
                )
              }
            />
          </div>
        </div>
        {authUser.id === productData.userId && (
          <div
            style={{ justifyContent: "end  ", display: "flex", flex: 1 }}
            className="gx-pt-4"
          >
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              onClick={navigateProductEdit}
              className="submit-btn"
            >
              Edit
            </Button>
          </div>
        )}
      </Card>
    )
  );
};

export default MyProductView;
