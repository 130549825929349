import React, {useState} from "react";
import {Button, Form, Input,Progress } from "antd";
import FormItem from "antd/es/form/FormItem";
import {updatedPasswordAction} from "../appRedux/actions";
import {useDispatch,useSelector} from "react-redux";
import { useQuery } from '../util/hooks';
import { useHistory } from 'react-router-dom';

const ResetPassword = () => {
  const dispatch = useDispatch();
  let query = useQuery();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [strength, setStrength] = useState(0);
  const history = useHistory();
  const loading = useSelector(({common}) => common.loading);



  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const passwordStrength = calculatePasswordStrength(newPassword);
    setStrength(passwordStrength);
  };

  const calculatePasswordStrength = (password) => {
    // Implement your password strength calculation logic here.
    // For example, you can check the length, character types, etc.
    // Return a value between 0 and 100 representing the strength.

    // Example: Checking length only
    if (password.length >= 8) {
      return 100; // Strong
    } else if (password.length >= 6) {
      return 60; // Medium
    } else if (password.length > 0) {
      return 30; // Weak
    } else {
      return 0; // Very weak
    }
  };


  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    dispatch(updatedPasswordAction(values?.password, query.get('token')));
  };

  const handleClick = () => {
    history.push('/signin')
  }

  return (

    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <div className="gx-login-header">Reset Password</div>
          <p className="gx-login-description">Please choose your new password</p>
        </div>
        <Form
          initialValues={{remember: true}}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">
          <FormItem rules= {[{required: true, message: 'Please input your new Password!'}]}  name="password">
            <Input.Password  size="large"
                    type="password"
                    placeholder="New Password"
                    onChange={handlePasswordChange}/>
          </FormItem>
          <FormItem  rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords do not match!')
                  );
                },
              }),
            ]}  name="confirmPassword">

            <Input.Password  size="large"
                    type="password"
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}/>
          </FormItem>

         { password && <Progress
            percent={strength}
            status={strength === 100 ? 'success' : strength >= 60 ? 'normal' : 'exception'}
            showInfo={false}
          />}


          <FormItem className="gx-text-center">
            <Button type="primary" size="large" htmlType="submit" block loading={loading}>
              Send
            </Button>
            <Button onClick={handleClick} type="secondary" size="large" block>
              Back to Login
            </Button>
          </FormItem>



        </Form>


      </div>
    </div>

  );
}

export default ResetPassword;
