import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  Upload,
  message,
  Row,
  Col,
  Collapse,
} from "antd";
import { useDispatch } from "react-redux";
import { getBusinessTypeOption } from "./../appRedux/actions/authAction";
import { useSelector } from "react-redux";
import { labelComponent } from "./../util/UtilComponents";
import { axiosInstance } from "../util/Api";
import SidebarLogo from "./Sidebar/SidebarLogo";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { Panel } = Collapse;

const FormItem = Form.Item;

const ExporterForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(({ common }) => common.loading);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [attachments, setAttachments] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getBusinessTypeOption());
  }, [dispatch]);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = async (values) => {
    try {
      // Combine attachments and form data
      const formData = {
        companyType: values.companyType,
        name: values.name,
        companyName: values.companyName,
        email: values.email,
        mobile: values.mobile,
        natureOfBusiness: values.natureOfBusiness,
      };

      let response = await axiosInstance.post("/registartion", formData);
      if (response?.status === 200) {
        message.success("Registration successful!");
        setTimeout(() => {
          history.push("/registrationsuccess");
        }, 1000);
      }
    } catch (error) {
      message.error("Registration failed, please try again.");
    }
  };

  const handleFileUpload = async (file, name) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosInstance.post(
        "/file-manager/single",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      const fileUrl = response.data.response?.url;

      setAttachments((prev) => ({
        ...prev,
        [name]: fileUrl,
      }));

      message.success(`${file.name} uploaded successfully.`);
    } catch (error) {
      message.error(`${file.name} file upload failed.`);
    }
  };

  const props = (fieldName) => ({
    customRequest: ({ file, onSuccess }) => {
      handleFileUpload(file, fieldName);
      onSuccess("ok");
    },
    onChange: (info) => {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  });

  return (
    <>
      <SidebarLogo />
      <div className="gx-login-container">
        <Row gutter={16}>
          <Col span={14}>
            <div className="gx-login-content" style={{ maxWidth: "80%" }}>
              <div className="gx-mb-4">
                <div className="gx-login-header">New Exporter Form</div>
                <div
                  style={{
                    padding: "10px 0",
                    fontWeight: "bold",
                    borderTop: "1px solid #d9d9d9",
                  }}
                >
                  If you want to become an Exporter, you need to register a new
                  company and you require set of documents. Please submit the
                  below form and our expert team will reach you to explain more
                  details.
                </div>
              </div>
              <Form
                form={form}
                layout="vertical"
                initialValues={{ remember: true }}
                name="signup"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: "Please input your Registration Type!",
                    },
                  ]}
                  name="companyType"
                  label={labelComponent("Company Registration Type", null)}
                >
                  <Select
                    size="large"
                    name="companyType"
                    placeholder="Registration Type"
                  >
                    <Select.Option value="Proprietorship" key="Proprietorship">
                      Proprietorship
                    </Select.Option>
                    <Select.Option
                      value="Partnership Firm"
                      key="Partnership Firm"
                    >
                      Partnership Firm
                    </Select.Option>
                    <Select.Option
                      value="Private Limited Company"
                      key="Private Limited Company"
                    >
                      Private Limited Company
                    </Select.Option>
                    <Select.Option
                      value="Limited Liability Partnership Firm"
                      key="Limited Liability Partnership Firm"
                    >
                      Limited Liability Partnership Firm
                    </Select.Option>
                    <Select.Option
                      value="One Person Company (OPC Private Limited)"
                      key="One Person Company (OPC Private Limited)"
                    >
                      One Person Company (OPC Private Limited)
                    </Select.Option>
                  </Select>
                </FormItem>

                <FormItem
                  rules={[
                    {
                      required: true,
                      message: "Please input your Name (As per your PAN)!",
                    },
                  ]}
                  name="name"
                  label={labelComponent("Name (As per your PAN)", null)}
                >
                  <Input
                    maxLength={75}
                    size="large"
                    placeholder="Name (As per your PAN)"
                  />
                </FormItem>

                <FormItem
                  rules={[
                    {
                      required: true,
                      message: "Please input your preferred new Company Name!",
                    },
                  ]}
                  name="companyName"
                  label={labelComponent(
                    "Your Preferred New Company Name",
                    null
                  )}
                >
                  <Input
                    maxLength={75}
                    size="large"
                    placeholder="Your Preferred New Company Name"
                  />
                </FormItem>

                <FormItem
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                  name="email"
                  label={labelComponent("Email", null)}
                >
                  <Input maxLength={75} size="large" placeholder="Email" />
                </FormItem>

                <FormItem
                  rules={[
                    { required: true, message: "Please input your Mobile!" },
                  ]}
                  name="mobile"
                  label={labelComponent("Mobile", null)}
                >
                  <Input maxLength={10} size="large" placeholder="Mobile" />
                </FormItem>

                <FormItem
                  rules={[
                    {
                      required: true,
                      message: "Please input your Nature of Business!",
                    },
                  ]}
                  name="natureOfBusiness"
                  label={labelComponent("Nature of Business", null)}
                >
                  <Input.TextArea
                    maxLength={100}
                    size="large"
                    placeholder="Nature of Business"
                  />
                </FormItem>
                <FormItem
                  name="agreedToTerms"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      validator: (rule, value) => {
                        if (agreedToTerms) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please agree to the Terms and Conditions."
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Checkbox
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                  >
                    I agree to my exim business&nbsp;
                    <a href="/terms-of-service" target="_blank">
                      Terms of Service
                    </a>
                    &nbsp;and&nbsp;
                    <a href="/privacy-policy" target="_blank">
                      Privacy Policy
                    </a>
                  </Checkbox>
                </FormItem>

                <FormItem className="gx-text-center">
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    block
                    loading={loading}
                  >
                    Submit
                  </Button>
                </FormItem>
              </Form>
            </div>
          </Col>
          <Col span={8}>
            <Collapse accordion>
              <Panel header="Sole Proprietorship" key="1">
                <p>
                  Sole Proprietary is a one-man entity where there is no
                  difference between the owner and the entity. All the
                  responsibility is on the owner himself. Likewise, he takes all
                  profit and losses himself.
                </p>
              </Panel>
              <Panel header="Partnership" key="2">
                <p>
                  Partnership is owned by two or more people where the ownership
                  and share in profit and losses are split between the owners in
                  an agreed ratio. It is established through a partnership deed.
                </p>
              </Panel>
              <Panel header="Private Limited Company" key="3">
                <p>
                  A Private Limited Company is a business entity formed under
                  the Companies Act, with a minimum of two members.
                </p>
              </Panel>
              <Panel header="Limited Liability Partnership (LLP)" key="4">
                <p>
                  LLP is a blend of sole proprietorship and partnership. Here, a
                  few or all partners have limited liability in the
                  organization.
                </p>
              </Panel>
              <Panel header="One Person Company (OPC)" key="5">
                <p>
                  An OPC is incorporated as a private limited company, where
                  there is only one member and a prohibition regarding an
                  invitation to the public for subscription of the company's
                  securities.
                </p>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ExporterForm;
