import { Input, Card, List, Modal, Image } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isValidString } from "util/ApiHelper";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { RFQStatus } from "../../util/Constant";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import EnquiryStatusTags from "../enquiries/EnquiriesStatus";
import { isArrayNonEmpty } from "../../util/ApiHelper";
import { getNewLine } from "util/helper";

const { TextArea } = Input;

const ProductDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const [productById, setProductById] = useState(null);
  const [userName, setUserName] = useState(null);

  const getproductById = async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const productById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/products/${id}`
        );
        setProductById(productById?.data?.data);
        getuserNameById(productById?.data?.data?.userId);
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };
  const getuserNameById = async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const userById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/users/${id}`
        );
        setUserName(userById?.data?.data?.companyName);
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };
  const navigateUserProfile = (id) => {
    if (id) {
      history.push(`/users/details?id=${id}`);
    }
  };
  useEffect(() => {
    if (isValidString(id)) {
      getproductById(id);
    }
  }, [dispatch, id]);

  const [fileName, setFileName] = useState("");
  const [showRejectModel, setShowRejectModel] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (isArrayNonEmpty(productById?.images)) {
      const fileNames = productById?.images?.map((image) => {
        return image;
      });
      setFileName(fileNames);
    }
  }, [productById]);

  const data = [
    {
      label: "Posted date",
      value: moment(productById?.createdAt).format("DD-MMM-YYYY"),
    },
    {
      label: "Name",
      value: productById?.name,
    },
    {
      label: "Company Name",
      value: (
        <div>
          {userName} &nbsp;&nbsp;{" "}
          <span
            onClick={() => navigateUserProfile(productById?.userId)}
            className={"myexim-link"}
          >
            View Profile
          </span>
        </div>
      ),
    },
    {
      label: "Description",
      value: getNewLine(productById?.description),
    },
    {
      label: "Specification",
      value: getNewLine(productById?.specification),
    },
    {
      label: "Categories",
      value: productById?.categories?.name,
    },
    {
      label: "Hs Code / Item Description",
      value: productById?.hscode
        ? `${productById.hscode.name} - ${productById.hscode.description}`
        : "N/A",
    },
    {
      label: "Status",
      value: <EnquiryStatusTags status={productById?.status} />,
    },
    {
      label: productById?.status === RFQStatus.REJECTED ? "Remarks" : "",
      value:
        productById?.status === RFQStatus.REJECTED ? productById?.remarks : "",
    },
  ];

  const onApprovedClicked = async () => {
    history.goBack();
    const data = {
      status: RFQStatus.APPROVED,
    };
    await axiosInstance.post(`${BASE_API_CONFIG.baseURL}/products/${id}`, data);
  };

  const onSubmitPressed = async () => {
    if (isValidString(reason)) {
      history.goBack();
      const data = {
        status: RFQStatus.REJECTED,
        remarks: reason,
      };
      await axiosInstance.post(
        `${BASE_API_CONFIG.baseURL}/products/${id}`,
        data
      );
    } else {
      alert("Please enter valid reason");
    }
  };

  const handleCancelModel = () => {
    setShowRejectModel(false);
    setReason("");
  };

  const onChangeClicked = (value) => {
    setReason(value);
  };

  return (
    <Card className="myexim-detail-card">
      <div className="myexim-table-title">
        <LeftOutlined
          className={"back-icon"}
          size="large"
          onClick={() => {
            history.goBack();
          }}
        />
        Product Details
      </div>
      <List
        className="gx-mt-4"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) =>
          item?.value && (
            <List.Item className="myexim-details-list">
              <List.Item.Meta
                title={<div>{item.label}</div>}
                description={item.value}
              />
            </List.Item>
          )
        }
      />
      {fileName?.length > 0 && (
        <div className="attachments">
          <div className="sub-header"> Attachments</div>
          <div className="gx-pt-4">
            {fileName?.map((fileName, index) => (
              <div key={index} className="gx-pb-3">
                <Image width={150} height={150} src={fileName?.src} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div
        style={{ justifyContent: "center", display: "flex", flex: 1 }}
        className="gx-pt-4"
      >
        <Button
          type="secondary"
          size="large"
          disabled={productById?.status === RFQStatus.REJECTED}
          onClick={() => {
            setShowRejectModel(true);
          }}
        >
          REJECT
        </Button>
        <Button
          type="primary"
          size="large"
          disabled={productById?.status === RFQStatus.APPROVED}
          onClick={() => {
            onApprovedClicked();
          }}
        >
          APPROVE
        </Button>
      </div>
      <Modal
        title="Confirmation"
        visible={showRejectModel}
        onCancel={handleCancelModel}
        centered // Center the modal vertically
        footer={null} // Hide the default footer
      >
        <div className={"delete-modal-content"}>
          <ExclamationCircleOutlined className={"delete-icon"} size={40} />{" "}
          {/* Delete warning icon */}
          <p>Do you want to reject this item?</p>
          <TextArea
            placeholder="Enter Reason"
            size={"large"}
            value={reason}
            onChange={(e) => onChangeClicked(e.target.value)}
          />
          <div style={{ marginTop: 30 }}>
            <Button type="default" onClick={() => handleCancelModel()}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => onSubmitPressed()}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export default ProductDetails;
