import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import { BASE_API_CONFIG, axiosInstance } from "../../util/Api";
import "../messages/messages.less";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { getMessagesAction } from "../../appRedux/actions/requestQuotationAction";

const Messages = ({ id, loggedInUserId, isQuotation }) => {
  const dispatch = useDispatch();
  const messageData = useSelector(
    (state) => state.requestQuotations.QuotationMessages
  );
  useEffect(() => {
    dispatch(getMessagesAction(isQuotation, id, loggedInUserId));
  }, [id, loggedInUserId, isQuotation]);
  return messageData?.length ? (
    <Card
      className="myexim-detail-card"
      style={{ width: isQuotation ? "100%" : "75%" }}
    >
      <div className="myexim-table-title">Messages</div>
      <Row className="gx-mt-4">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="myexim-messages">
            {messageData.map((message) => (
              <div
                key={message.id}
                className={`gx-media gx-flex-nowrap message-user-info ${
                  message.isOwnMessage ? "own-message" : "other-message"
                }`}
              >
                <div>
                  <h5
                    className={
                      message.isOwnMessage
                        ? "own-message-text"
                        : "other-message-text"
                    }
                  >
                    {message?.sender?.companyName}
                  </h5>
                  <p className="message-text">{message.text}</p>
                  <span
                    className={`message-created-at ${
                      message.isOwnMessage ? "own-message" : "other-message"
                    }`}
                  >
                    {message.createdAt}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Card>
  ) : null;
};

export default Messages;
