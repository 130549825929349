import { all, takeLatest, put, fork } from "redux-saga/effects";
import { apiCall } from "../../util/Api";
import { MY_EXIM_API_Products } from "../services/myProductsNetwork";
import { MY_PRODUCTS_TYPES } from "../types/myProductsTypes";
import { showAlert } from "../../util/helper";
import {
  isArrayNonEmpty,
  isValidElement,
  isValidString,
} from "../../util/ApiHelper";
import { getProductsAction } from "../actions/myProductsAction";
import { push } from "connected-react-router";
import { FETCH_START, FETCH_SUCCESS } from "./../types/commonTypes";

function* makeGetProductsList(action) {
  try {
    yield put({ type: FETCH_START });
    const response = yield apiCall(MY_EXIM_API_Products.getProductList, action);
    if (isValidElement(response?.data?.data?.rows)) {
      yield put({
        type: MY_PRODUCTS_TYPES.GET_PRODUCTS_LIST_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: MY_PRODUCTS_TYPES.GET_PRODUCTS_LIST_SUCCESS,
        payload: null,
      });
    }
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  } finally {
    yield put({ type: FETCH_SUCCESS });
  }
}

function* makeDeleteProduct(action) {
  try {
    yield apiCall(MY_EXIM_API_Products.deleteProduct, action);
    yield put({ type: MY_PRODUCTS_TYPES.DELETE_MY_PRODUCT_SUCCESS });
    yield put(getProductsAction);
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  }
}
function* makeGetSearchProduct(action) {
  try {
    const response = yield apiCall(MY_EXIM_API_Products.searchProducts, action);
    yield put({
      type: MY_PRODUCTS_TYPES.SEARCH_PRODUCTS_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  }
}

function* makeCreateProduct(action) {
  try {
    let response;
    if (isValidString(action?.id)) {
      response = yield apiCall(MY_EXIM_API_Products.updateProduct, action);
    } else {
      response = yield apiCall(MY_EXIM_API_Products.createProduct, action);
    }
    if (isValidElement(response)) {
      yield put(push(`/my-products`));
      yield put({
        type: MY_PRODUCTS_TYPES.CREATE_PRODUCT_SUCCESS,
        payload: response,
      });
    }
  } catch (e) {
    showAlert("error", "Something went wrong");
  }
}

function* makeCategoryList() {
  try {
    const response = yield apiCall(MY_EXIM_API_Products.categoryList);
    yield fork(makeHSCodeList);
    yield fork(makeGetUnitList);
    if (isArrayNonEmpty(response?.data?.data?.rows)) {
      yield put({
        type: MY_PRODUCTS_TYPES.GET_CATEGORY_LIST_SUCCESS,
        payload: response?.data?.data?.rows,
      });
    } else {
      yield put({
        type: MY_PRODUCTS_TYPES.GET_CATEGORY_LIST_SUCCESS,
        payload: null,
      });
    }
  } catch (e) {
    showAlert("error", "Category List API Failed");
  }
}

function* makeGetUnitList() {
  try {
    const response = yield apiCall(MY_EXIM_API_Products.productUnitList);
    if (isArrayNonEmpty(response?.data?.data)) {
      yield put({
        type: MY_PRODUCTS_TYPES.PRODUCT_UNIT_LIST_SUCCESS,
        payload: response?.data?.data,
      });
    }
  } catch (e) {
    showAlert("error", "Category List API Failed");
  }
}

function* makeHSCodeList() {
  try {
    const response = yield apiCall(MY_EXIM_API_Products.hsCodeList);
    if (isArrayNonEmpty(response?.data?.data?.rows)) {
      yield put({
        type: MY_PRODUCTS_TYPES.GET_HS_CODE_SUCCESS,
        payload: response?.data?.data?.rows,
      });
    } else {
      yield put({ type: MY_PRODUCTS_TYPES.GET_HS_CODE_SUCCESS, payload: null });
    }
  } catch (e) {
    showAlert("error", "HS Code API Failed");
  }
}

function* makeProductById(action) {
  try {
    yield put({ type: FETCH_START });
    const response = yield apiCall(MY_EXIM_API_Products.productById, action);
    if (isValidElement(response?.data?.data)) {
      yield put({
        type: MY_PRODUCTS_TYPES.GET_PRODUCT_BY_ID_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: MY_PRODUCTS_TYPES.GET_PRODUCT_BY_ID_SUCCESS,
        payload: null,
      });
    }
  } catch (e) {
    showAlert("error", e.message);
  } finally {
    yield put({ type: FETCH_SUCCESS });
  }
}

export default function* myProductSaga() {
  yield all([
    takeLatest(MY_PRODUCTS_TYPES.GET_PRODUCTS_LIST, makeGetProductsList),
    takeLatest(MY_PRODUCTS_TYPES.CREATE_PRODUCT, makeCreateProduct),
    takeLatest(MY_PRODUCTS_TYPES.GET_CATEGORY_LIST, makeCategoryList),
    takeLatest(MY_PRODUCTS_TYPES.GET_HS_CODE, makeHSCodeList),
    takeLatest(MY_PRODUCTS_TYPES.DELETE_MY_PRODUCT, makeDeleteProduct),
    takeLatest(MY_PRODUCTS_TYPES.GET_SEARCH_PRODUCTS, makeGetSearchProduct),
    takeLatest(MY_PRODUCTS_TYPES.GET_PRODUCT_BY_ID, makeProductById),
    takeLatest(MY_PRODUCTS_TYPES.GET_PRODUCT_UNIT_LIST, makeGetUnitList),
  ]);
}
