import React from "react";
import { Route, Switch } from "react-router-dom";
import PromotionsList from "./promotionsList";
import AddEditPromotion from "./AddEditPromotion";
import promotionsView from "./promotionsView";
const App = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={PromotionsList} />
    <Route path={`${match.url}/view/:id?`} component={promotionsView} />
    <Route path={`${match.url}/add-edit/:id?`} component={AddEditPromotion} />
  </Switch>
);

export default App;
