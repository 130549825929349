import { MY_PRODUCTS_TYPES } from "../types/myProductsTypes";

export const getProductsAction = (page = 1, limit = 10) => {
  return {
    type: MY_PRODUCTS_TYPES.GET_PRODUCTS_LIST,
    page,
    limit,
  };
};

export const createProductAction = (data, id = "") => {
  return {
    type: MY_PRODUCTS_TYPES.CREATE_PRODUCT,
    data,
    id,
  };
};

export const getCategoryListAction = () => {
  return {
    type: MY_PRODUCTS_TYPES.GET_CATEGORY_LIST,
  };
};

export const getHSCodeAction = () => {
  return {
    type: MY_PRODUCTS_TYPES.GET_HS_CODE,
  };
};

export const deleteProductAction = (product_id) => {
  return {
    type: MY_PRODUCTS_TYPES.DELETE_MY_PRODUCT,
    product_id,
  };
};

export const searchProductsAction = (searchText) => {
  return {
    type: MY_PRODUCTS_TYPES.GET_SEARCH_PRODUCTS,
    searchText,
  };
};
export const getProductsById = (id) => {
  return {
    type: MY_PRODUCTS_TYPES.GET_PRODUCT_BY_ID,
    id,
  };
};

export const getUnitListAction = () => {
  return {
    type: MY_PRODUCTS_TYPES.GET_PRODUCT_UNIT_LIST,
  };
};
