import React, { useEffect, useState } from "react";
import { Card, Table, Button, Space, message } from "antd";
import { Dropdown } from "antd";
import { DeleteOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { isArrayNonEmpty, isValidElement } from "../../util/ApiHelper";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { BASE_API_CONFIG, axiosInstance } from "../../util/Api";
import EnquiryStatusTags from "../enquiries/EnquiriesStatus";
import FilterButton from "../../components/FilterButton/FilterButton";
import ListEmptyComponent from "../../components/ListEmptyComponent/ListEmptyComponent";

let navigateToDetails;
let deleteById;

const formatDate = (createdAt) => {
  return new Date(createdAt).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const columns = [
  {
    title: "Promotion Title",
    key: "Title",
    render: (record) => <div>{record?.title}</div>,
  },
  {
    title: "Promotion Category",
    key: "Promotion Category",
    render: (record) => <div>{record?.category}</div>,
  },
  {
    title: "Promotion Start",
    key: "Promotion Start",
    render: (record) => <div>{formatDate(record?.startDate)}</div>,
  },
  {
    title: "Promotion End",
    key: "Promotion End",
    render: (record) => <div>{formatDate(record?.endDate)}</div>,
  },
  {
    title: "Promotion Status",
    key: "Promotion Status",
    render: (record) => {
      return <EnquiryStatusTags status={record?.status} />;
    },
  },
  {
    title: "Actions",
    key: "actions",
    render: (record) => (
      <div className="myexim-action-icon" onClick={(e) => e.stopPropagation()}>
        <Dropdown
          menu={{
            items: [
              {
                label: "View Details",
                key: "1",
                icon: <EyeOutlined />,
                onClick: () => {
                  navigateToDetails(record?.id);
                },
              },
              {
                label: "Delete",
                key: "2",
                icon: <DeleteOutlined />,
                onClick: () => {
                  deleteById(record?.id);
                },
              },
            ],
          }}
          trigger={["click"]}
        >
          <Space>
            <MoreOutlined />
          </Space>
        </Dropdown>
      </div>
    ),
  },
];

const PromotionsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [promotionsAdminData, setpromotionsAdminData] = useState(null);
  const { loading } = useSelector(({ common }) => common);

  const page = 1;
  const limit = 10;

  useEffect(() => {
    fetchPromotions();
  }, [dispatch]);

  const onShowSizeChange = (current, pageSize) => {};

  const fetchPromotions = async () => {
    try {
      dispatch(fetchStart());
      const response = await axiosInstance.get(
        `${BASE_API_CONFIG.baseURL}/promotions?page=1&limit=10`
      );
      const prom = response?.data?.data;
      setpromotionsAdminData(prom.rows);
    } catch (error) {
      console.error("Error fetching promotions:", error);
    } finally {
      dispatch(fetchSuccess());
    }
  };
  deleteById = async (id) => {
    try {
      const response = await axiosInstance.delete(
        `${BASE_API_CONFIG.baseURL}/promotions/${id}`
      );
      if (response.status === 200) {
        message.success("success", "Promotion deleted");
        await fetchPromotions();
      } else {
        message.error("error", "Failed to delete promotion");
      }
    } catch (error) {
      console.error("Error deleting promotions:", error);
    }
  };

  navigateToDetails = (id) => {
    if (isValidElement(id)) {
      history.push(`promotions/view?id=${id}`);
    } else {
      history?.push("promotions/add-edit");
    }
  };

  return (
    <Card
      title={
        <div style={{ display: "flex" }}>
          <div className="myexim-table-title">Promotions</div>
        </div>
      }
    >
      <div>
        <div className="myexim-table-header">
          {/* <div>
              <Input
                placeholder="Search Promotions"
                size="large"
                onChange={onSearch}
                suffix={<SearchOutlined />}
                onPressEnter={(e) => onSearch(e.target.value)}
              />
            </div> */}
          {/* <div>
            <Button type="primary" onClick={() => navigateToDetails()}>
              Add New Promotion
            </Button>
          </div> */}
        </div>
        {/* <FilterButton
          className={"rfq-filter-button"}
          availableStatus={Object.values(EnquiryStatus)}
          onSelectStatus={handleSelectStatus}
        /> */}
        {isArrayNonEmpty(promotionsAdminData) ? (
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            onRow={(record) => {
              return {
                onClick: (e) => {
                  if (
                    !e.target.classList.contains(
                      "ant-dropdown-menu-title-content"
                    )
                  ) {
                    navigateToDetails(record?.id);
                  }
                },
              };
            }}
            className="gx-table-responsive my-product-table myexim-table"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={promotionsAdminData}
            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
              pageSize: 10,
              total: promotionsAdminData?.count,
              onChange: onShowSizeChange,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        ) : (
          !loading && (
            <ListEmptyComponent
              message="No Promotions available"
              onButtonClick={() => navigateToDetails()}
            />
          )
        )}
      </div>
    </Card>
  );
};

export default PromotionsList;
