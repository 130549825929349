import React from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import MainApp from "./MainApp";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import PasswordSuccess from "../PasswordSuccess";
import Login from "../Auth/Admin/Login";

export const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const App = () => {
  const authUser = useSelector(({ auth }) => auth.userData);
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <Switch>
        <Route exact path="/signin" component={Login} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/resetpassword" component={ResetPassword} />
        <Route exact path="/passwordsuccess" component={PasswordSuccess} />
        <RestrictedRoute
          path={`${match.url}`}
          authUser={authUser}
          location={location}
          component={MainApp}
        />
      </Switch>
  );
};

export default React.memo(App);
