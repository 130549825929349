import React from "react";
import { Button, Form, Input } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../appRedux/actions";
import InfoPopup from "./../util/InfoPopup";
import { labelComponent } from "./../util/UtilComponents";
import SidebarLogo from "./Sidebar/SidebarLogo";

const FormItem = Form.Item;

const SignIn = () => {
  const dispatch = useDispatch();
  const loading = useSelector(({ common }) => common.loading);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(loginAction(values.email, values.password));
  };

  return (
    <>
      <SidebarLogo />
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-mb-4">
            <div className="gx-login-header">Welcome Back</div>
            <p className="gx-login-description">
              Don't have an account? &nbsp;{" "}
              <Link className="myexim-link" to="/signup">
                Register
              </Link>
            </p>
          </div>
          <Form
            initialValues={{ remember: true }}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <FormItem
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent("Email", null)}
              rules={[{ required: true, message: "Please input your E-mail!" }]}
              name="email"
            >
              <Input size="large" placeholder="Email" />
            </FormItem>
            <FormItem
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
              name="password"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent("Password", null)}
            >
              <Input.Password size="large" placeholder="Password" />
            </FormItem>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: 15,
              }}
            >
              <Link className="myexim-link" to="/forgotpassword">
                Forgot password
              </Link>
            </div>
            <FormItem
              className="gx-text-center"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                block
                loading={loading}
              >
                Log in
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SignIn;
