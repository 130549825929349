import { all, takeLatest, put } from "redux-saga/effects";
import { apiCall } from "../../util/Api";
import { showAlert } from "../../util/helper";
import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_PORTS_SUCCESS,
  GET_PORTS,
} from "../types/commonTypes";
import { MY_EXIM_API_COMMON } from "./../services/commonNetwork";

function* makeGetRequestCountries(action) {
  try {
    const response = yield apiCall(MY_EXIM_API_COMMON.getCountries, action);
    yield put({
      type: GET_COUNTRIES_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (e) {
    showAlert("error", e?.message);
  } finally {
  }
}

function* makeGetRequestPorts(action) {
  try {
    const response = yield apiCall(MY_EXIM_API_COMMON.getPorts, action);
    yield put({
      type: GET_PORTS_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (e) {
    showAlert("error", e?.message);
  } finally {
  }
}

export default function* CommonSaga() {
  yield all([
    takeLatest(GET_COUNTRIES, makeGetRequestCountries),
    takeLatest(GET_PORTS, makeGetRequestPorts),
  ]);
}
