import React, { useEffect, useState } from "react";
import { Card, Table, Input, Button, Avatar } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { isValidString } from "../../util/ApiHelper";
import { BUSINESS_TYPE } from "../../util/Constant";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { logoFromString } from "./../../util/helper";
import { BUSINESS_CARD_STATUS } from './../../util/Constant';

const BusinessCard = () => {
  const authUser = useSelector(({ auth }) => auth?.userData);
  const [businessCardData, setBusinessCardData] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const buttonClicked = async (id, statusChange) => {
    let data = {
      status: statusChange,
    };
    await axiosInstance.put(
      `${BASE_API_CONFIG.baseURL}/businesscard/${id}`,
      data
    );
    getBusinessCardDetails();
  };

  const deleteRequest = async (id) => {
    await axiosInstance.delete(
      `${BASE_API_CONFIG.baseURL}/businesscard/${id}`
    );
    getBusinessCardDetails();

  }

  const columns = [
    {
      title: "Business Name",
      key: "Business Name",
      dataIndex: "businessName",
      sorter: true,
      width: "25%",
      render: (text, record) => (
        <div
          className={
            "column-product-name-desc gx-pointer gx-flex-row gx-align-items-center"
          }
        >
          <div className={"gx-pr-2"}>
            <Avatar
              src={
                isValidString(record?.requestTo?.companyLogo) &&
                record?.requestTo?.companyLogo
              }
              className="gx-avatar gx-pointer"
              alt=""
              style={{ height: 40, width: 40 }}
            >
              {!authUser?.companyLogo &&
                logoFromString(record?.requestTo?.companyName)}
            </Avatar>
          </div>
          <div className={"product-name"}>{record?.requestTo?.companyName}</div>
        </div>
      ),
    },
    {
      title: "Country",
      key: "country",
      sorter: true,
      width: "25%",
      render: (record) => (
        <div>
          <div>
            <div>{record?.requestTo?.country}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      sorter: true,
      width: "25%",
      render: (record) => (
        <div>
          {authUser?.businessTypeId !== BUSINESS_TYPE.BUYER ? (
            <Button
              type="secondary"
              size="medium"
              htmlType="submit"
              style={{ width: 150,marginBottom:0 }}
              onClick={() => deleteRequest(record?.id)}
            >
              Decline
            </Button>
          ) : (
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: 150 }}
                onClick={() => buttonClicked(record?.id, "accepted")}
              >
                Accept
              </Button>

              <Button
                type="secondary"
                size="large"
                htmlType="submit"
                style={{ width: 150 }}
                onClick={() => buttonClicked(record?.id, "rejected")}
              >
                Reject
              </Button>
            </div>
          )}
        </div>
      ),
    },
  ];
  const getBusinessCardDetails = async () => {
    let response;
    if (authUser?.businessTypeId === BUSINESS_TYPE.BUYER) {
      response = await axiosInstance.get(
        `${BASE_API_CONFIG.baseURL}/businesscard?requestToId=${authUser?.id}&status=${BUSINESS_CARD_STATUS.SENT}`
      );
    } else {
      response = await axiosInstance.get(
        `${BASE_API_CONFIG.baseURL}/businesscard?type=my_sent_request&page=1&limit=10`
      );
    }
    setBusinessCardData(response?.data?.data);
  };

  useEffect(() => {
    getBusinessCardDetails();
  }, []);

  // const searchProductList=useSelector((state) => state.myProducts.searchProducts);

  const onShowSizeChange = async (current, pageSize) => {
    const response = await axiosInstance.get(
      `${BASE_API_CONFIG.baseURL}/businesscard?type=my_sent_request&page=1&limit=10`
    );
    setBusinessCardData(response?.rows);
  };
  const onSearch = (e) => {
    if (isValidString(e?.target?.value)) {
      setSearchText(e.target.value);
    }
  };
  const tableData = businessCardData;

  // const tableData= isValidString(searchText) && searchText.length > 0 ? isArrayNonEmpty(searchProductList?.rows)?searchProductList:[]:businessCardData;

  return (
    <Card
      title={<div className="myexim-table-title">Business Card Request</div>}
    >
      <div className="myexim-table-header" style={{ marginBottom: 10 }}>
        {/* <div>
          <Input
            placeholder="Search"
            size="large"
            onChange={onSearch}
            suffix={<SearchOutlined />}
            onPressEnter={(e) => onSearch(e.target.value)}
          />
        </div> */}
      </div>
      <Table
       rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
        className="gx-table-responsive my-product-table myexim-table"
        total={tableData?.count}
        columns={columns}
        dataSource={tableData?.rows}
        pagination={{
          position: ["bottomRight"],
          showSizeChanger: true,
          pageSize: 5,
          total: tableData?.count,
          onChange: onShowSizeChange,
        }}
      />
    </Card>
  );
};

export default BusinessCard;
