import { isValidElement } from "./ApiHelper";
import InfoPopup from "./InfoPopup";

export const labelComponent = (label, contentText) => {
  return (
    <div>
      {label}&nbsp;&nbsp;
      {isValidElement(contentText) ? <InfoPopup content={contentText} /> : null}
    </div>
  );
};
