import React from "react";
import { Route, Switch } from "react-router-dom";
import PromotionsList from "./PromotionsList";
import PromotionDetails from "./PromotionDetails";

const App = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={PromotionsList} />
    <Route path={`${match.url}/view`} component={PromotionDetails} />
  </Switch>
);

export default App;
