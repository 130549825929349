import React from "react";
import { Select } from "antd";

const { Option } = Select;

const FilterButton = ({ availableStatus, onSelectStatus }) => {
  const formatOptionLabel = (optionLabel) => {
    return optionLabel
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  return (
    <div
      className="filter"
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <div style={{ fontSize: 16, marginBottom: 16 }}>
        <span>Filter </span>
        <Select
          placeholder="Select Status"
          style={{ width: "auto", minWidth: 200 }}
          onChange={onSelectStatus}
          optionLabelProp="label"
        >
          {availableStatus.map((status) => (
            <Option
              key={status}
              value={status}
              label={formatOptionLabel(status)}
            >
              {formatOptionLabel(status)}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default FilterButton;
