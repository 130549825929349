import {NETWORK_METHOD} from "../../util/ApiHelper";
import {BASE_API_CONFIG} from "../../util/Api";


export const MY_EXIM_API_COMMON = {
  getCountries: () => ({
    method: NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/countries`
  }),
  getPorts: () => ({
    method: NETWORK_METHOD.GET,
    url: `${BASE_API_CONFIG.baseURL}/ports`
  })
}

