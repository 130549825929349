import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}`}
      component={asyncComponent(() => import("./UserProfile"))}
    />
    <Route
      exact
      path={`${match.url}/product-view/:id?`}
      component={asyncComponent(() => import("../myProducts/MyProductView"))}
    />
  </Switch>
);

export default App;
