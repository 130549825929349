import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchStart, fetchSuccess } from "../../../appRedux/actions";
import { useDispatch } from "react-redux";
import { BASE_API_CONFIG, axiosInstance } from "../../../util/Api";
import "../banner/banner.less";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import promoimg from "../../../assets/images/promo.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PromotionBanner = () => {
  const dispatch = useDispatch();
  const [Promotions, setPromotions] = useState([]);

  useEffect(() => {
    fetchPromotions();
  }, [dispatch]);

  const fetchPromotions = async () => {
    try {
      dispatch(fetchStart());
      const response = await axiosInstance.get(
        `${BASE_API_CONFIG.baseURL}/promotions/offers?page=1&limit=100`
      );
      setPromotions(response?.data?.data?.rows);
    } catch (error) {
      console.error("Error fetching promotions:", error);
    } finally {
      dispatch(fetchSuccess());
    }
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
        <LeftOutlined style={{ color: "#FFFFFF" }} />
      </div>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="custom-arrow custom-next-arrow" onClick={onClick}>
        <RightOutlined style={{ color: "#FFFFFF" }} />
      </div>
    );
  };
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return Promotions && Promotions.length > 0 ? (
      <div>
      <Slider {...settings}>
        {Promotions.map((promotion) => (
          <div className="promotion-banner">
            <div className="banner-image">
              <img src={promoimg} alt="promo-img" />
            </div>

            <div key={promotion.id} className="promotion-item">
              <h2 className="banner-title">{promotion.title}</h2>
              <p className="banner-desc">{promotion.description}</p>
              <Link to={`/banner-view?id=${promotion.id}`}>
                <Button className="avail-now">Avail this offer</Button>
              </Link>
            </div>
          </div>
        ))}
      </Slider>
      </div>
  ) : null;
};

export default PromotionBanner;
