import { all, takeLatest, put } from "redux-saga/effects";
import { apiCall } from "../../util/Api";
import { MY_EXIM_API_Products } from "../services/myProductsNetwork";
import { MY_PRODUCTS_TYPES } from "../types/myProductsTypes";
import { formatCreatedAt, showAlert } from "../../util/helper";
import { getProductsAction } from "../actions/myProductsAction";
import { REQUEST_QUOTATIONS } from "../types/RequestQuotationAction";
import { MY_EXIM_API_RFQ } from "../services/requestQuotationsNetwork";
import {
  getRequestQuotationList,
  searchRFQDataAction,
} from "../actions/requestQuotationAction";
import { FETCH_START, FETCH_SUCCESS } from "./../types/commonTypes";

function* makeGetRequestQuotationList(action) {
  try {
    yield put({ type: FETCH_START });
    const response = yield apiCall(
      MY_EXIM_API_RFQ.getRequestQuotationList,
      action
    );
    yield put({
      type: REQUEST_QUOTATIONS.REQUEST_QUOTATIONS_LIST_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (e) {
    showAlert("error", e?.message);
  } finally {
    yield put({ type: FETCH_SUCCESS });
  }
}

function* makeDeleteRFQData(action) {
  try {
    const response = yield apiCall(
      MY_EXIM_API_RFQ.deleteRequestQuotation,
      action
    );
    yield put({ type: REQUEST_QUOTATIONS.DELETE_QUOTATIONS_FROM_LIST_SUCCESS });
    yield put(getRequestQuotationList());
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  }
}
function* makeGetSearchRFQData(action) {
  try {
    const response = yield apiCall(
      MY_EXIM_API_RFQ.searchRequestQuotation,
      action
    );
    yield put({
      type: REQUEST_QUOTATIONS.SEARCH_PRODUCTS_QUOTATIONS_FROM_LIST_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  }
}
function* makeGetMessages(action) {
  try {
    const response = yield apiCall(
      MY_EXIM_API_RFQ.getQuotationMessages,
      action
    );
    console.log("response==>", response);
    const modifiedMessages = response?.data?.data?.map((message) => ({
      ...message,
      isOwnMessage: message.senderId === action.loggedInUserId,
      createdAt: formatCreatedAt(message.createdAt),
    }));
    yield put({
      type: REQUEST_QUOTATIONS.GET_MESSAGES_SUCCESS,
      payload: modifiedMessages,
    });
  } catch (e) {
    showAlert("error", e?.response?.data?.message);
  }
}

export default function* requestQuotationSaga() {
  yield all([
    takeLatest(
      REQUEST_QUOTATIONS.GET_REQUEST_QUOTATIONS_LIST,
      makeGetRequestQuotationList
    ),
    takeLatest(
      REQUEST_QUOTATIONS.DELETE_QUOTATIONS_FROM_LIST,
      makeDeleteRFQData
    ),
    takeLatest(
      REQUEST_QUOTATIONS.GET_SEARCH_QUOTATIONS_FROM_LIST,
      makeGetSearchRFQData
    ),
    takeLatest(REQUEST_QUOTATIONS.GET_MESSAGES, makeGetMessages),
  ]);
}
