import React, { useEffect, useState } from "react";
import { Card, Table, Button, Space } from "antd";
import { Dropdown } from "antd";
import { DeleteOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryListAction,
  getHSCodeAction,
  getProductsAction,
  searchProductsAction,
  deleteProductAction,
} from "../../appRedux/actions/myProductsAction";
import { useHistory } from "react-router-dom";
import {
  isArrayNonEmpty,
  isValidElement,
  isValidString,
} from "../../util/ApiHelper";
import EnquiryStatusTags from "../../admin/enquiries/EnquiriesStatus";
import ListEmptyComponent from "../../components/ListEmptyComponent/ListEmptyComponent";

let onProductClick, navigateToDetails;

const columns = [
  {
    title: "Product Name",
    key: "Product Name",
    dataIndex: "name",
    sorter: true,
    width: "25%",
    render: (text, record) => (
      <div className={"gx-flex-row gx-align-items-center"}>
        <div className={"gx-pr-2"}>
          <img
            alt="..."
            className={"gx-size-40 gx-rounded-base gx-border-2"}
            src={isValidString(record?.primaryImage) && record?.primaryImage}
          />
        </div>
        <div className={"highlighted-column"}>{text}</div>
      </div>
    ),
  },
  {
    title: "Category",
    key: "Product Name",
    sorter: true,
    width: "25%",
    render: (record) => <div>{record?.categories?.name}</div>,
  },
  {
    title: "HS Code",
    key: "hsCode",
    render: (record) => <div>{record?.hscode?.code}</div>,
  },
  {
    title: "Status",
    key: "Status",
    sorter: true,
    render: (record) => {
      return <EnquiryStatusTags status={record?.status} />;
    },
  },
  {
    title: "Actions",
    key: "actions",
    render: (record) => (
      <div className="myexim-action-icon" onClick={(e) => e.stopPropagation()}>
        <Dropdown
          menu={{
            items: [
              {
                label: "View",
                key: "1",
                icon: <EyeOutlined />,
                onClick: () => {
                  navigateToDetails(record?.id);
                },
              },
              {
                label: "Delete",
                key: "2",
                icon: <DeleteOutlined />,
                onClick: () => {
                  onProductClick(record);
                },
              },
            ],
          }}
          trigger={["click"]}
        >
          <MoreOutlined />
        </Dropdown>
      </div>
    ),
  },
];

const MyProductsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getProductsAction());
    dispatch(getCategoryListAction());
    dispatch(getHSCodeAction());
  }, [dispatch]);
  const [searchText, setSearchText] = useState(null);
  const productList = useSelector((state) => state.myProducts.productsList);
  const searchProductList = useSelector(
    (state) => state.myProducts.searchProducts
  );
  const { loading } = useSelector(({ common }) => common);

  const onShowSizeChange = (current, pageSize) => {
    dispatch(getProductsAction(current, pageSize));
  };
  onProductClick = (item) => {
    dispatch(deleteProductAction(item?.id));
    dispatch(getProductsAction(1, 10));
  };

  const onSearch = (e) => {
    if (isValidString(e?.target?.value)) {
      dispatch(searchProductsAction(e.target.value));
      setSearchText(e.target.value);
    }
  };
  navigateToDetails = (id) => {
    if (isValidElement(id)) {
      history.push(`my-products/details?id=${id}`);
    } else {
      history?.push("my-products/edit");
    }
  };

  const tableData =
    isValidString(searchText) && searchText.length > 0
      ? isArrayNonEmpty(searchProductList?.rows)
        ? searchProductList
        : []
      : productList;

  return (
    <Card
      title={
        <div style={{ display: "flex" }}>
          <div className="myexim-table-title">My Products</div>
          {isArrayNonEmpty(tableData?.rows) && (
            <div style={{ paddingLeft: 15 }}>
              <Button type="primary" onClick={() => navigateToDetails()}>
                + Create New Product
              </Button>
            </div>
          )}
        </div>
      }
    >
      <div>
        <div className="myexim-table-header">
          {/* <div>
            <Input
              placeholder="Search Products"
              size="large"
              onChange={onSearch}
              suffix={<SearchOutlined />}
              onPressEnter={(e) => onSearch(e.target.value)}
            />
          </div> */}
          {/* <div>
          <Button type="primary" onClick={() => navigateToDetails()}>
            Add New Product
          </Button>
        </div> */}
        </div>
        {isArrayNonEmpty(tableData?.rows) ? (
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            onRow={(record) => {
              return {
                onClick: () => {
                  navigateToDetails(record?.id);
                },
              };
            }}
            className="gx-table-responsive my-product-table myexim-table"
            total={tableData?.count}
            columns={columns}
            dataSource={tableData?.rows}
            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
              pageSize: 10,
              total: tableData?.count,
              onChange: onShowSizeChange,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            footer={() =>
              tableData?.count > 0 ? (
                <div
                  style={{
                    padding: "10px 0",
                    fontWeight: "bold",
                    borderTop: "1px solid #d9d9d9",
                  }}
                >
                  Note: Your Products with "Pending Approval" status require
                  review by our team and will be approved or rejected within 24
                  hours.
                </div>
              ) : null
            }
          />
        ) : (
          !loading && (
            <ListEmptyComponent
              message="Create Your Products to get the Enquiries for your Listed Products."
              buttonText="+Create Your New Products"
              onButtonClick={() => navigateToDetails()}
            />
          )
        )}
      </div>
    </Card>
  );
};

export default MyProductsList;
