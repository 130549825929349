import React from "react";
import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { message } from "antd";

export const CustomSuccessMessage = (props) => (
  <div style={{ display: "flex" }}>
    <CheckCircleFilled />
    <div className="notification-message">
      <div className="message-title">{props?.title}</div>
      <div>{props.message}</div>
    </div>
    {/* <CloseCircleOutlined
      className="close-icon"
      onClick={() => message.destroy()}
      style={{ color: "red" }}
    /> */}
  </div>
);
