import React from "react";
import {Route, Switch} from "react-router-dom";
import MyWebsiteQueries from './MyWebsiteQueries';

const App = ({match}) => (
  <Switch>
    <Route exact path={`${match.url}`} component={MyWebsiteQueries}/>
  </Switch>
);

export default App;
