import React, { useEffect, useState } from "react";
import { Card, Image, List } from "antd";
import { isValidElement } from "util/ApiHelper";
import { useHistory } from "react-router-dom";
import EnquiryStatusTags from "./EnquiriesStatus";
import { Button } from "antd";
import { tabKeys } from "./EnquiryTabs";
import { LeftOutlined } from "@ant-design/icons";
import { isLessThan24Hrs } from "util/helper";
import { EnquiryStatus } from "util/Constant";
import { isArrayNonEmpty } from "../../util/ApiHelper";
import { getNewLine } from "util/helper";

const EnquiryDetails = ({ enquriesData, onNext }) => {
  const history = useHistory();
  const moment = require("moment");

  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (isArrayNonEmpty(enquriesData?.productImages)) {
      const fileNames = enquriesData?.productImages?.map((image) => {
        return image;
      });
      setFileName(fileNames);
    }
  }, [enquriesData]);

  const getStatus = (record) => {
    let status = record?.readReciept[0]?.status;
    if (!status) {
      status = isLessThan24Hrs(record?.createdAt)
        ? EnquiryStatus.NEW_ENQUIRY
        : EnquiryStatus.AWAITING_FOR_QUOTATION;
    }
    return <EnquiryStatusTags status={status} />;
  };

  const data = [
    {
      label: "Posted Date",
      value: moment(enquriesData?.createdAt).format("DD-MMM-YYYY"),
    },
    {
      label: "Company Name",
      value: (
        <div>
          {enquriesData?.user?.companyName} &nbsp;&nbsp;{" "}
          <span
            onClick={() => navigateBusinessCard(enquriesData?.user?.id)}
            className={"myexim-link"}
          >
            View Profile
          </span>
        </div>
      ),
    },
    {
      label: "Category",
      value: enquriesData?.categories?.name,
    },
    {
      label: "HS Code / Item Description",
      value: enquriesData?.hscode
        ? `${enquriesData.hscode.name} - ${enquriesData.hscode.description}`
        : "N/A",
    },
    {
      label: "Product Name",
      value: enquriesData?.productName,
    },
    {
      label: "Quantity",
      value: `${
        enquriesData?.qty
          ? enquriesData?.qty + " " + enquriesData?.units
          : enquriesData?.noOfContainers +
            " " +
            (enquriesData?.noOfContainers > 1 ? "Containers" : "Container")
      }`,
    },
    ...(enquriesData?.netWeightPerContainer
      ? [
          {
            label: "Net Weight Per Container",
            value: `${enquriesData?.netWeightPerContainer} Killograms`,
          },
        ]
      : []),
    {
      label: "Requirement Details",
      value: getNewLine(enquriesData?.description),
    },
    {
      label: "Source Country",
      value: enquriesData?.sourceCountry,
    },
    {
      label: "Destination Country",
      value: enquriesData?.destinationCountry,
    },
    {
      label: "Destination Port",
      value: enquriesData?.destinationPort,
    },
    {
      label: "Status",
      value: getStatus(enquriesData),
    },
  ];

  const navigateBusinessCard = (id) => {
    if (id) {
      history.push(`/user-profile?id=${id}`);
    }
  };

  return (
    isValidElement(enquriesData) && (
      <Card className="myexim-detail-card">
        <div className="myexim-table-title">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          Enquiry Details
        </div>
        <List
          className="gx-mt-4"
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) =>
            item?.value && (
              <List.Item className="myexim-details-list">
                <List.Item.Meta
                  title={<div>{item.label}</div>}
                  description={item.value}
                />
              </List.Item>
            )
          }
        />
        {fileName?.length > 0 && (
          <div className="attachments">
            <div className="sub-header">Attachments</div>
            <div>
              Please find the below attachment as a reference to the requirement
            </div>
            <div className="gx-pt-4">
              {fileName?.map((fileName, index) => (
                <div key={index} className="gx-pb-3">
                  <Image width={150} height={150} src={fileName?.src} />
                </div>
              ))}
            </div>
          </div>
        )}
        <div
          style={{ justifyContent: "center", display: "flex", flex: 1 }}
          className="gx-pt-4"
        >
          <Button
            type="secondary"
            size="large"
            className="submit-btn"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            className="submit-btn"
            onClick={() => {
              onNext(tabKeys.QUOTATION_TAB);
            }}
          >
            {enquriesData.quotations ? "Send Quotation" : "Prepare Quotation"}
          </Button>
        </div>
      </Card>
    )
  );
};

export default EnquiryDetails;
