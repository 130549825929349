import { Input, Card, List, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isValidString } from "util/ApiHelper";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { RFQStatus } from "../../util/Constant";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";

const { TextArea } = Input;

const PromotionDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const [promotionAdminData, setpromotionAdminData] = useState(null);
  const formattedStartDate = moment(promotionAdminData?.startDate).format(
    "DD-MM-YYYY"
  );
  const formattedEndDate = moment(promotionAdminData?.endDate).format(
    "DD-MM-YYYY"
  );

  const getpromotionAdminData = async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const promotionAdminData = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/registartion/${id}`
        );
        setpromotionAdminData(promotionAdminData?.data?.data);
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };

  useEffect(() => {
    if (isValidString(id)) {
      getpromotionAdminData(id);
    }
  }, [dispatch, id]);

  const [showRejectModel, setShowRejectModel] = useState(false);
  const [reason, setReason] = useState("");

  const data = [
    {
      label: "Company Type",
      value: promotionAdminData?.companyType,
    },
    {
      label: "Company Name",
      value: promotionAdminData?.companyName,
    },
    {
      label: "Name",
      value: promotionAdminData?.name,
    },
    {
      label: "Email",
      value: promotionAdminData?.email,
    },
    {
      label: "Mobile",
      value: promotionAdminData?.mobile,
    },
    {
      label: "Nature of Business",
      value: promotionAdminData?.natureOfBusiness,
    },
  ];

  const onApprovedClicked = async () => {
    if (promotionAdminData) {
      history.goBack();
      const data = {
        title: promotionAdminData?.promotionTitle,
        description: promotionAdminData?.promotionMessage,
        category: promotionAdminData?.promotionCategory,
        categoryId: promotionAdminData?.productCategory,
        hsCodeId: promotionAdminData?.hscode,
        startDate: promotionAdminData?.promotionStart,
        endDate: promotionAdminData?.promotionEnd,
        countryId: promotionAdminData?.destinationCountry,
        portId: promotionAdminData?.destinationPort,
        status: RFQStatus.APPROVED,
      };
      const response = await axiosInstance.put(
        `${BASE_API_CONFIG.baseURL}/registartion/${id}`,
        data
      );
      if (response.status === 200) {
        message.success("success", "Promotion saved successfully");
        history.goBack();
      } else {
        message.error("error", "Failed to save promotion");
      }
    } else {
      const response = await axiosInstance.post(
        `${BASE_API_CONFIG.baseURL}/registartion`,
        data
      );
      if (response.status === 200) {
        message.success("success", "Promotion saved successfully");
        history.goBack();
      } else {
        message.error("error", "Failed to save promotion");
      }
    }
  };

  const onSubmitPressed = async () => {
    if (isValidString(reason)) {
      history.goBack();
      const data = {
        status: RFQStatus.REJECTED,
        remarks: reason,
      };
      await axiosInstance.post(
        `${BASE_API_CONFIG.baseURL}/registartion/${id}`,
        data
      );
    } else {
      alert("Please enter valid reason");
    }
  };

  const handleCancelModel = () => {
    setShowRejectModel(false);
    setReason("");
  };

  const onChangeClicked = (value) => {
    setReason(value);
  };

  return (
    <Card className="myexim-detail-card">
      <div className="myexim-table-title">
        <LeftOutlined
          className={"back-icon"}
          size="large"
          onClick={() => {
            history.goBack();
          }}
        />
        Exporter Registration Details
      </div>
      <List
        className="gx-mt-4"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) =>
          item?.value && (
            <List.Item className="myexim-details-list">
              <List.Item.Meta
                title={<div>{item.label}</div>}
                description={item.value}
              />
            </List.Item>
          )
        }
      />

      <Modal
        title="Confirmation"
        visible={showRejectModel}
        onCancel={handleCancelModel}
        centered // Center the modal vertically
        footer={null} // Hide the default footer
      >
        <div className={"delete-modal-content"}>
          <ExclamationCircleOutlined className={"delete-icon"} size={40} />{" "}
          {/* Delete warning icon */}
          <p>Do you want to reject this promotion?</p>
          <TextArea
            placeholder="Enter Reason"
            size={"large"}
            value={reason}
            onChange={(e) => onChangeClicked(e.target.value)}
          />
          <div style={{ marginTop: 30 }}>
            <Button type="default" onClick={() => handleCancelModel()}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => onSubmitPressed()}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export default PromotionDetails;
