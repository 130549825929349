export const NETWORK_METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    HEAD: 'HEAD',
    PATCH: 'PATCH'
  };

  export const isValidString = (data) => {
    return data !== null && data !== undefined && data !== '';
  };
  export const isValidElement = (data) => {
    return data !== null && data !== undefined;
  };

  export const isArrayNonEmpty = (response) => {
    return isValidElement(response) && isValidElement(response?.length) && response?.length > 0;
  };

export const isValidNumber = (data) => {
  return typeof data === "number" && !isNaN(data);
};

export const getLabel = (index) => {
  return `Attachment  ${index+1}`
}
