import { AUTH_TYPES } from "../types/authTypes";

const INITIAL_STATE = {
  userData: JSON.parse(localStorage.getItem("userData")),
  loginSuccess: null,
  showLoader: false,
  businessTypes: null,
  resetPasswordToken: null,
};

const Auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_TYPES.CREATE_USER_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case AUTH_TYPES.LOGIN_ACTION_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case AUTH_TYPES.LOGOUT_ACTION:
      localStorage.removeItem("userData");
      return {
        ...state,
        userData: null,
      };
    case AUTH_TYPES.PUT_BUSINESS_TYPE_OPTION_SUCCESS:
      return {
        ...state,
        businessTypes: action.payload,
      };
    case AUTH_TYPES.UPDATE_AUTH_USER:
      return {
        ...state,
        userData: action.data,
      };
    case AUTH_TYPES.CHECK_CURRENT_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordToken: action.payload,
      };
    case AUTH_TYPES.RESET_PASSWORD_TOKEN:
      return {
        ...state,
        resetPasswordToken: null,
      };

    default:
      return state;
  }
};

export default Auth;
