import React, { useEffect, useState, useCallback } from "react";
import { Card, Table, Dropdown } from "antd";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import EnquiryStatusTags from "./EnquiriesStatus";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, isLessThan24Hrs } from "util/helper";
import { isArrayNonEmpty, isValidString } from "../../util/ApiHelper";
import { EnquiryStatus } from "util/Constant";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { axiosInstance } from "util/Api";
import { BASE_API_CONFIG } from "./../../util/Api";
import { getBusinessBasedEnquiryTableColumns } from "./utils";
import ListEmptyComponent from "../../components/ListEmptyComponent/ListEmptyComponent";

let navigateToDetails;
const columns = [
  {
    title: "Posted Date",
    key: "createdAt",
    render: (record) => <div>{formatDate(record?.createdAt)}</div>,
  },
  {
    title: "Buyer Company Name",
    key: "companyName",
    sorter: true,
    render: (record) => (
      <div className="highlighted-column">{record?.user?.companyName}</div>
    ),
  },
  {
    title: "Product Name",
    key: "productName",
    sorter: true,
    render: (record) => <div>{record?.productName}</div>,
  },
  {
    title: "Quantity",
    key: "quantityRequired",
    render: (record) => (
      <div>
        {record?.qty ? (
          <>
            {record?.qty} {record?.units}
          </>
        ) : (
          <>
            {record?.noOfContainers}{" "}
            {record?.noOfContainers == 1 ? "Container" : "Containers"}
          </>
        )}
      </div>
    ),
  },
  {
    title: "Status",
    key: "Status",
    sorter: true,
    render: (record) => {
      let status = record?.readReciept[0]?.status;
      if (!status) {
        status = isLessThan24Hrs(record?.createdAt)
          ? EnquiryStatus.NEW_ENQUIRY
          : EnquiryStatus.AWAITING_FOR_QUOTATION;
      }
      return <EnquiryStatusTags status={status} />;
    },
  },
  {
    title: "Actions",
    key: "actions",
    render: (record) => (
      <div className="myexim-action-icon" onClick={(e) => e.stopPropagation()}>
        <Dropdown
          menu={{
            items: [
              {
                label: "View Details",
                key: "1",
                icon: <EyeOutlined />,
                onClick: () => {
                  navigateToDetails(
                    record?.id,
                    record?.readReciept[0]?.quotationId
                  );
                },
              },
            ],
          }}
          trigger={["click"]}
        >
          <MoreOutlined />
        </Dropdown>
      </div>
    ),
  },
];

const EnquiriesList = ({ fromDashboard }) => {
  const authUser = useSelector(({ auth }) => auth.userData);

  const dispatch = useDispatch();
  const currentPage = 1;
  const dataSize = fromDashboard ? 5 : 10;
  const [tableData, setTableData] = useState([]);

  const { loading } = useSelector(({ common }) => common);
  const authUserId = useSelector(({ auth }) => auth?.userData?.businessTypeId);
  if (authUserId !== 3 && authUserId !== 4) {
    const hasDestinationColumns = columns.some(
      (column) =>
        column.key === "destinationCountry" || column.key === "destinationPort"
    );
    if (!hasDestinationColumns) {
      columns.splice(4, 0, {
        title: "Destination Country",
        key: "destinationCountry",
        render: (record) => <div>{record?.destinationCountry}</div>,
      });

      columns.splice(5, 0, {
        title: "Destination Port",
        key: "destinationPort",
        render: (record) => <div>{record?.destinationPort}</div>,
      });
    }
  }
  const fetchEnquiries = useCallback(
    async (page, limit) => {
      try {
        dispatch(fetchStart());
        const response = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/enquiries/businessType?page=${page}&limit=${limit}`
        );
        setTableData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching enquiries:", error);
      } finally {
        dispatch(fetchSuccess());
      }
    },
    [dispatch]
  );

  const onShowSizeChange = (current, pageSize) => {
    dispatch(fetchEnquiries(current, pageSize));
  };
  const history = useHistory();

  navigateToDetails = (id, qid) => {
    if (isValidString(id) && isValidString(qid)) {
      history.push(`enquiries/details?id=${id}&qid=${qid || ""}`);
    } else if (isValidString(id)) {
      history.push(`enquiries/details?id=${id}`);
    }
  };

  useEffect(() => {
    fetchEnquiries(currentPage, dataSize);
  }, [dataSize, fetchEnquiries]);

  return (
    <Card
      title={
        <div className="myexim-table-title">
          {authUserId == 5 || authUserId == 7 ? "Enquiries" : "Enquiries"}
        </div>
      }
    >
      {!fromDashboard && <div className="myexim-table-header"></div>}
      {isArrayNonEmpty(tableData?.rows) ? (
        <Table
          rowKey={"id"}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          onRow={(record) => {
            return {
              onClick: (e) => {
                // Add specific conditions here for menu items
                if (
                  !e.target.classList.contains(
                    "ant-dropdown-menu-title-content"
                  )
                ) {
                  navigateToDetails(
                    record?.id,
                    record?.readReciept[0]?.quotationId
                  );
                }
              },
            };
          }}
          className="gx-table-responsive rfq-table myexim-table"
          columns={columns.filter((obj) =>
            getBusinessBasedEnquiryTableColumns(
              authUser?.businessTypes?.code
            )?.includes(obj.key)
          )}
          dataSource={tableData?.rows}
          pagination={
            !fromDashboard && {
              position: ["bottomRight"],
              pageSize: 10,
              showSizeChanger: true,
              total: tableData?.count,
              onChange: onShowSizeChange,
            }
          }
        />
      ) : (
        !loading && (
          <ListEmptyComponent
            message="Create Your Products to get the Enquiries for your Listed Products"
            buttonText=" + Create New Products"
            onButtonClick={() => history.push("my-products/edit")}
          />
        )
      )}
      {fromDashboard && isArrayNonEmpty(tableData?.rows) && (
        <div
          style={{ display: "flex", justifyContent: "end", marginBottom: 15 }}
        >
          <Link className="myexim-link" to="/enquiries">
            View More
          </Link>
        </div>
      )}
    </Card>
  );
};

export default EnquiriesList;
