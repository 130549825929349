import React from "react";
import { Route, Switch } from "react-router-dom";
import home from "./home";
import requestQuotation from "./requestQuotation";
import myProducts from "./myProducts";
import enquiries from "./enquiries";
import myProfile from "./myProfile";
import BusinessCard from "./businessCard";
import UserProfile from "./userProfile";
import promotions from "./promotions";
import BannerView from "./home/banner/bannerView";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route exact path={`${match.url}`} component={home} />
      <Route path={`${match.url}rfq`} component={requestQuotation} />
      <Route path={`${match.url}my-products`} component={myProducts} />
      <Route path={`${match.url}enquiries`} component={enquiries} />
      <Route path={`${match.url}business-card`} component={BusinessCard} />
      <Route path={`${match.url}my-profile`} component={myProfile} />
      <Route path={`${match.url}user-profile`} component={UserProfile} />
      <Route path={`${match.url}promotions`} component={promotions} />
      <Route path={`${match.url}banner-view`} component={BannerView} />
    </Switch>
  </div>
);

export default React.memo(App);
