import {useLocation,useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch,useSelector} from "react-redux";
import FormItem from "antd/es/form/FormItem";
import { useQuery } from '../util/hooks';
import {sendOtpAction, verifyOTP} from "../appRedux/actions";

const OTPVerify = () => {
  const history = useHistory();
  let query = useQuery();

  const dispatch = useDispatch();
  const loading = useSelector(({common}) => common.loading);


  const initialTimerValue = 59;
  const [timer, setTimer] = useState(59);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mobile = '+' + searchParams.get('mobile').trim();

  useEffect(() => {
    if (timer > 0 && isResendDisabled) {
      const countdownInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdownInterval);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }
  }, [timer, isResendDisabled]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
  };

  const handleResendClick = () => {
    if (timer === 0) {
      setTimer(initialTimerValue);
      setIsResendDisabled(true);
      dispatch(sendOtpAction(mobile));
    }
  };


  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    dispatch(verifyOTP(values?.otp, query.get('mobile'), query.get('email')));
  };

  const handleClick = () => {
    history.push('/signin')
  }

  return (

    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <div className="gx-login-header">Verify Your Mobile Number</div>
          <p className="gx-login-description"> Thank you for creating an account with us.We have sent a OTP code to your mobile number {mobile}</p>
          <p className="gx-login-description">Didnt receive the OTP?  Wait for {formatTime(timer)} Seconds - &nbsp; <span className={`gx-link gx-btn-link ${isResendDisabled && 'disabled'}`} onClick={handleResendClick}>Resend OTP</span></p>
        </div>
        <Form
          initialValues={{remember: true}}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">
          <FormItem rules= {[{required: true, message: 'Please input your otp!'}]}  name="otp">

            <Input  size="large" maxLength={6}
                    placeholder="OTP"/>
          </FormItem>



          <FormItem className="gx-text-center">
            <Button type="primary" size="large" htmlType="submit" block loading={loading}>
              Verify
            </Button>
            <Button onClick={handleClick} type="secondary" size="large"  block>
              Back to Login
          </Button>
          </FormItem>
        </Form>


      </div>
    </div>

  );
}

export default OTPVerify;
