import React from "react";
import {Route, Switch} from "react-router-dom";
import RFQList from './RFQList';
import RFQDetails from './RFQDetails';
import QuotationList from './QuotationList';
import QuotationsDetails from './QuotationsDetails';
import RFQView from "./RFQView";



const App = ({match}) => (
    <Switch>
      <Route exact path={`${match.url}`} component={RFQList}/>
      <Route path={`${match.url}/view/:id?`} component={RFQView}/>
      <Route path={`${match.url}/details/:id?`} component={RFQDetails}/>
      <Route path={`${match.url}/clone/:id?`} component={RFQDetails}/>
      <Route path={`${match.url}/quotations-list/:id?`} component={QuotationList}/>
      <Route path={`${match.url}/quotations-details/:id?`} component={QuotationsDetails}/>
    </Switch>
);

export default App;
