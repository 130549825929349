import React from "react";
import { Route, Switch } from "react-router-dom";
import RegistartionList from "./RegistartionList";
import RegistartionDetails from "./RegistartionDetails";

const App = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={RegistartionList} />
    <Route path={`${match.url}/view`} component={RegistartionDetails} />
  </Switch>
);

export default App;
