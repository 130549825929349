import { BUSINESS_TYPE } from './../../util/Constant';

const DEFAULT_TABLE_COLUMN = [
    'createdAt','companyName','productName','quantityRequired','destinationCountry','destinationPort','Status','actions'
];


const MANUFACTURER_TABLE_COLUMN = [
    'createdAt','companyName','productName','quantityRequired','Status','actions'
];

export const getBusinessBasedEnquiryTableColumns = (businessType) => {
    switch(businessType){
        case BUSINESS_TYPE.MANUFACTURER:
            return MANUFACTURER_TABLE_COLUMN;
        default:
            return DEFAULT_TABLE_COLUMN;
    }

}