import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router-dom";
import SidebarLogo from "./Sidebar/SidebarLogo";

const SuccessPage = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push("/exporter"); // Change to your desired route
  };

  return (
    <>
      <SidebarLogo />
      <Result
        status="success"
        title="Successfully Submitted!"
        subTitle="Your form has been successfully submitted. Thank you for your input."
        extra={[
          <Button
            type="primary"
            key="dashboard"
            onClick={() => history.push("/login")}
          >
            Go to Login
          </Button>,
          <Button key="back" onClick={handleGoBack}>
            Fill Another Form
          </Button>,
        ]}
      />
    </>
  );
};

export default SuccessPage;
