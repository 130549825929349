import React from "react";
import { Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const InfoPopup = (props) => (
  <Popover content={props?.content}>
    <InfoCircleOutlined />
  </Popover>
);

export default InfoPopup;
