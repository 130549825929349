import React, { useEffect, useState } from "react";
import { Button, Card, List } from "antd";
import {
  isArrayNonEmpty,
  isValidElement,
  isValidString,
} from "../../util/ApiHelper";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined } from "@ant-design/icons";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import { EnquiryStatus } from "../../util/Constant";
import { getNewLine } from "util/helper";
import RFQStatusTags from "./RFQStatus";
import { BUSINESS_TYPE_LABEL } from "./../../util/Constant";
import { isBuyer } from "../../util/helper";

const RFQView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const userData = useSelector(({ auth }) => auth?.userData);
  const [rfqData, setRfqData] = useState(null);
  const [fileName, setFileName] = useState(null);

  const getRFQById = async (id) => {
    if (isValidString(id)) {
      dispatch(fetchStart());
      try {
        const rfqById = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/enquiries/${id}`
        );
        setRfqData(rfqById?.data?.data);
      } catch (error) {
      } finally {
        dispatch(fetchSuccess());
      }
    }
  };

  useEffect(() => {
    getRFQById(id);
  }, [id]);

  useEffect(() => {
    if (isArrayNonEmpty(rfqData?.productImages)) {
      const fileNames = rfqData?.productImages?.map((image) => {
        return image;
      });
      setFileName(fileNames);
    }
  }, [rfqData]);

  const HsCodeandDescription = `${
    rfqData?.hscode?.code
  } - ${rfqData?.hscode?.description?.replace(/[\n\r"]/g, "")}`;

  const data = [
    {
      label: "Product Name",
      value: rfqData?.productName,
    },
    {
      label: "Product Category",
      value: rfqData?.categories.name,
    },
    {
      label: "HS code / Item Description",
      value: `${rfqData?.hscode?.name} - ${rfqData?.hscode?.description}`,
    },
    {
      label: "Net Weight Per Container",
      value: rfqData?.netWeightPerContainer,
    },
    {
      label: "Quantity",
      value: `${
        rfqData?.qty
          ? rfqData?.qty + " " + rfqData?.units
          : rfqData?.noOfContainers +
            " " +
            (rfqData?.noOfContainers > 1 ? "Containers" : "Container")
      }`,
    },
    {
      label: "Requirements",
      value: getNewLine(rfqData?.description),
    },
    {
      label: "Source Country",
      value: rfqData?.sourceCountry,
    },
    {
      label: "Source Port",
      value: rfqData?.sourcePort,
    },
    {
      label: "Destination Country",
      value: rfqData?.destinationCountry,
    },
    {
      label: "Destination Port",
      value: rfqData?.destinationPort,
    },
    {
      label: "Status",
      value: <RFQStatusTags status={rfqData?.status} />,
    },
    {
      label: "Remarks",
      value: rfqData?.remarks,
    },
  ];
  if (!isBuyer(userData)) {
    data.unshift({
      label: "Party",
      value: BUSINESS_TYPE_LABEL[rfqData?.targetedBusinessType?.[0]],
    });
  }

  //26000 netWeightPerContainer

  const navigateProfileEdit = () => {
    history.push(`/rfq/details/${id}`);
  };
  console.log("rfqData", rfqData);
  return (
    isValidElement(rfqData) && (
      <Card className="myexim-detail-card" style={{ width: "75%" }}>
        <div className="myexim-table-title">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />{" "}
          Enquiry Details
        </div>
        <div className="logo-with-list gx-mt-4">
          <div style={{ paddingRight: 16 }}>
            {isValidString(rfqData?.primaryImage) && (
              <div className={"logo-container"}>
                <div className={"logo-image"}>
                  <img
                    alt="..."
                    src={rfqData?.primaryImage}
                    width={120}
                    height={120}
                  />
                </div>
              </div>
            )}
          </div>
          <div style={{ flex: 1 }}>
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) =>
                item?.value && (
                  <List.Item className="myexim-details-list">
                    <List.Item.Meta
                      title={<div>{item.label}</div>}
                      description={item.value}
                    />
                  </List.Item>
                )
              }
            />
            {fileName?.length > 0 && (
              <div className="attachments">
                <div className="sub-header"> Attachments</div>
                <div className="gx-pt-4">
                  {fileName?.map((fileName, index) => (
                    <div key={index} className="gx-pb-3">
                      <a
                        className="myexim-link"
                        href={`${fileName.src}`}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        {fileName.originalName}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{ justifyContent: "end  ", display: "flex", flex: 1 }}
          className="gx-pt-4"
        >
          <Button
            type="secondary"
            size="large"
            onClick={() => {
              history.goBack();
            }}
            className="submit-btn"
          >
            Back
          </Button>
          {rfqData?.totalQuotations > 0 ? (
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              onClick={() => {
                history.push(`/rfq/quotations-list/${id}`);
              }}
              className="submit-btn"
            >
              View Quotations
            </Button>
          ) : (
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              onClick={navigateProfileEdit}
              className="submit-btn"
              disabled={rfqData?.status === EnquiryStatus.PUBLISHED}
            >
              Edit
            </Button>
          )}
        </div>
      </Card>
    )
  );
};

export default RFQView;
