import React from "react";
import { Tag } from "antd";
import {EnquiryStatus} from 'util/Constant';

const statusDetails = {
  [EnquiryStatus.QUOTATION_SENT]: {
    label: "Quotation Sent",
    color: "#F5993F",
  },
  [EnquiryStatus.ORDER_PLACED]: {
    label: "Awaiting For Order Confirmation",
    color: "#40507E",
  },
  [EnquiryStatus.ORDER_CONFIRMED]: {
    label: "Order Confirmed",
    color: "#2FB07E",
  },
  [EnquiryStatus.NEW_ENQUIRY]: {
    label: "New Enquiry",
    color: "blue",
  },
  [EnquiryStatus.AWAITING_FOR_QUOTATION]: {
    label: "Awaiting For Quotation",
    color: "#9d9d9d",
  }
};

const EnquiryStatusTags = ({ status }) => {
  return (
    <div>
      <Tag color={statusDetails[status]?.color}>
        {statusDetails[status]?.label}
      </Tag>
    </div>
  );
};

export default EnquiryStatusTags;
