import React, { useEffect, useState } from "react";
import { Card, Form, Image, List, Modal, Space } from "antd";
import { useHistory } from "react-router-dom";
import { BASE_API_CONFIG } from "util/Api";
import { LeftOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";
import { axiosInstance } from "util/Api";
import { EnquiryStatus } from "util/Constant";
import { isLessThan24Hrs } from "util/helper";
import EnquiryStatusTags from "./EnquiriesStatus";
import { isArrayNonEmpty, isValidString } from "../../util/ApiHelper";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import Messages from "../messages";
import { useDispatch } from "react-redux";
import { getMessagesAction } from "../../appRedux/actions/requestQuotationAction";

const QuotationsSubmitDetails = ({ quotationData, onNext }) => {
  const history = useHistory();
  const [fileName, setFileName] = useState("");
  console.log(quotationData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageSubmit, setMessageSubmit] = useState(false);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { status, price, units, description, expiryDate, currencyCode, id } =
    quotationData?.data?.data ?? {};
  const formattedexpiryDate = moment(expiryDate).format("DD-MM-YYYY");

  useEffect(
    () => {
      if (isArrayNonEmpty(quotationData?.data?.data.attachments)) {
        const fileNames = quotationData?.data?.data.attachments?.map(
          (image) => {
            return image;
          }
        );
        setFileName(fileNames);
      }
      if (messageSubmit) {
        setMessageSubmit(false);
      }
    },
    [quotationData],
    [messageSubmit]
  );

  const onSubmit = async () => {
    let data = {
      id: id,
      enquiryId: quotationData?.data?.data.enquiryId,
      receiverId: quotationData?.data?.data.receiverId,
      senderId: quotationData?.data?.data.senderId,
      status: EnquiryStatus.ORDER_CONFIRMED,
    };
    const response = await axiosInstance.post(
      `${BASE_API_CONFIG.baseURL}/quotations/` + id,
      data
    );
    if (response.status === 200) {
      history.goBack();
    }
  };

  const getStatus = (record) => {
    let status = record?.readReciept[0]?.status;
    if (!status) {
      status = isLessThan24Hrs(record?.createdAt)
        ? EnquiryStatus.NEW_ENQUIRY
        : EnquiryStatus.AWAITING_FOR_QUOTATION;
    }
    return <EnquiryStatusTags status={status} />;
  };

  const data = [
    {
      label: "Currency",
      value: currencyCode || "--",
    },
    {
      label: "Price",
      value: price,
    },
    {
      label: "Unit of Measurement",
      value: units,
    },
    {
      label: "Expiry date",
      value: formattedexpiryDate || "--",
    },
    {
      label: "Response Message",
      value: description,
    },
    {
      label: "Status",
      value: getStatus(quotationData?.data?.data),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        setIsModalOpen(false);
        axiosInstance
          .post(`${BASE_API_CONFIG.baseURL}/messages`, {
            text: values.text,
            senderId: quotationData?.data?.data?.senderId,
            sender: quotationData?.data?.data?.sender.name,
            receiverId: quotationData?.data?.data?.receiverId,
            receiver: quotationData?.data?.data?.receiver.name,
            enquiryId: quotationData?.data?.data?.enquiryId,
            quotationId: quotationData?.data?.data?.id,
          })
          .then((response) => {
            setMessageSubmit(true);
            dispatch(
              getMessagesAction(
                true,
                quotationData?.data?.data?.id,
                quotationData?.data?.data?.senderId
              )
            );
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  return (
    <>
      <Card className="myexim-detail-card">
        <div className="myexim-table-title">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          Quotation Details
        </div>
        <List
          className="gx-mt-4"
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) =>
            item?.value && (
              <List.Item className="myexim-details-list">
                <List.Item.Meta
                  title={<div>{item.label}</div>}
                  description={item.value}
                />
              </List.Item>
            )
          }
        />
        {fileName?.length > 0 && (
          <div className="attachments">
            <div className="sub-header">Attachments</div>
            <div className="gx-pt-4">
              {fileName?.map((fileName, index) => (
                <div key={index} className="gx-pb-3">
                  <Image width={150} height={150} src={fileName?.src} />
                </div>
              ))}
            </div>
          </div>
        )}
        <Row style={{ textAlign: "right", flexDirection: "row-reverse" }}>
          <Space>
            <Col>
              <Button size="large" type="primary" onClick={showModal}>
                Send Message
              </Button>
            </Col>
            {status === EnquiryStatus.ORDER_PLACED && (
              <Col>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className={"submit-btn"}
                  onClick={() => onSubmit()}
                >
                  Confirm Order
                </Button>
              </Col>
            )}
          </Space>
        </Row>
      </Card>
      <Messages
        id={quotationData?.data?.data?.id}
        loggedInUserId={quotationData?.data?.data?.senderId}
        isQuotation={true}
      />
      {isModalOpen ? (
        <Modal
          title="Send Message"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form form={form} name="negotiateForm">
            <Form.Item
              name="text"
              rules={[{ required: true, message: "Please enter text!" }]}
            >
              <TextArea rows={10} placeholder="Add your notes" />
            </Form.Item>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

export default QuotationsSubmitDetails;
